exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-corporate-index-js": () => import("./../../../src/pages/corporate/index.js" /* webpackChunkName: "component---src-pages-corporate-index-js" */),
  "component---src-pages-corporate-tour-js": () => import("./../../../src/pages/corporate/tour.js" /* webpackChunkName: "component---src-pages-corporate-tour-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-insurance-js": () => import("./../../../src/pages/insurance.js" /* webpackChunkName: "component---src-pages-insurance-js" */),
  "component---src-pages-message-index-js": () => import("./../../../src/pages/message/index.js" /* webpackChunkName: "component---src-pages-message-index-js" */),
  "component---src-pages-news-index-js": () => import("./../../../src/pages/news/index.js" /* webpackChunkName: "component---src-pages-news-index-js" */),
  "component---src-pages-privacy-consent-js": () => import("./../../../src/pages/privacy_consent.js" /* webpackChunkName: "component---src-pages-privacy-consent-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-security-policy-js": () => import("./../../../src/pages/security_policy.js" /* webpackChunkName: "component---src-pages-security-policy-js" */),
  "component---src-pages-service-index-js": () => import("./../../../src/pages/service/index.js" /* webpackChunkName: "component---src-pages-service-index-js" */),
  "component---src-pages-service-navinavi-asset-management-game-index-js": () => import("./../../../src/pages/service/navinavi-asset-management-game/index.js" /* webpackChunkName: "component---src-pages-service-navinavi-asset-management-game-index-js" */),
  "component---src-pages-service-navinavi-insurance-index-js": () => import("./../../../src/pages/service/navinavi-insurance/index.js" /* webpackChunkName: "component---src-pages-service-navinavi-insurance-index-js" */),
  "component---src-pages-site-policy-js": () => import("./../../../src/pages/site_policy.js" /* webpackChunkName: "component---src-pages-site-policy-js" */),
  "component---src-pages-staff-index-js": () => import("./../../../src/pages/staff/index.js" /* webpackChunkName: "component---src-pages-staff-index-js" */),
  "component---src-pages-staff-pickup-01-js": () => import("./../../../src/pages/staff/pickup01.js" /* webpackChunkName: "component---src-pages-staff-pickup-01-js" */),
  "component---src-pages-staff-pickup-04-js": () => import("./../../../src/pages/staff/pickup04.js" /* webpackChunkName: "component---src-pages-staff-pickup-04-js" */),
  "component---src-pages-staff-pickup-06-js": () => import("./../../../src/pages/staff/pickup06.js" /* webpackChunkName: "component---src-pages-staff-pickup-06-js" */),
  "component---src-pages-vision-index-js": () => import("./../../../src/pages/vision/index.js" /* webpackChunkName: "component---src-pages-vision-index-js" */),
  "component---src-templates-category-js": () => import("./../../../src/templates/category.js" /* webpackChunkName: "component---src-templates-category-js" */),
  "component---src-templates-detail-news-js": () => import("./../../../src/templates/detail_news.js" /* webpackChunkName: "component---src-templates-detail-news-js" */)
}

